import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"
import Intro from "../components/Intro"
import Histories from "../components/Histories"
import AboutSubNav from "../components/AboutSubNav"
import Accordion from "../components/Accordion"
import Differences from "../components/Differences"
import ExpandinImageComponent from "../components/ExpandinImageComponent"
import { renderRichText } from 'gatsby-source-contentful/rich-text'

const About = ({
  data: {
    about,
    intro,
    allContentfulAboutFaq: { nodes: faqs },
    allContentfulWhatWeDo: { nodes: weDo },
  },
}) => {
  return <Layout indicator dark>
    <SEO title="About" description="About highrock capital"/>
    <div className="section-center-narrow">
        <Intro data={intro}/>
    </div>
    <div className="about-content">
    <AboutSubNav/>
    <section id="intro" className="section-color-gray">
      <div className="about-page section-center-narrow section-padding">
          <article className="about-text">
            <p>{renderRichText(about.information)}</p>
          </article>
      </div>
    </section>
      <section id="history" className="section-center-narrow section-padding">
      <h2>Our History</h2>
      <Histories/>
      </section>
      <section id="difference" className="section-center-narrow">
      <h2>What makes us different</h2>
      <Differences/>
      </section>
      <section id="what-we-do" className="section-center-narrow section-padding">
      <h2>What we do</h2>
      <ExpandinImageComponent info={weDo}/>
      </section>
      <section id="faq" className="section-center-narrow">
      <h2>Frequently asked questions</h2>
        { faqs.map((faq, i )=> { 
          return <Accordion key={i} faq={faq}/>
        })}
      </section>
    </div>
  </Layout>
}

export const query = graphql`
  {
    about:contentfulAbout {
      title
      information {
        raw
      }
    }
    intro:contentfulHeaderAbout {
      title
      subTitle
      description {
        description
      }
    }
    allContentfulAboutFaq {
      nodes {
        id
        answer {
          answer
        }
        question {
          question
        }
      }
    }
    allContentfulWhatWeDo {
      nodes {
        url
        description {
          description
        }
        title
        id
        image {
          fluid {
            src
          }
        }
      }
    }
  }
`

export default About


