import React from "react";
import styled from "styled-components";

const GridContainer = styled.div`
    min-height: 200px;
    display: flex;
    justify-content: space-between;

    .box-item {
        width: 48%;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
        border-radius: 4px;
        overflow: hidden;

        img {
            width: 100%;
        }

        .box-text-wrapper {
            padding: 30px 20px;
            display: flex;
            flex-direction: column;
            width: 100%;
            position: relative;
            
            a {
                font-size: 16px;
                text-transform: uppercase;
                font-weight: 500;
                font-family: var(--ff-secondary);
                text-align: center;
                width: 100%;
                margin-bottom: 20px;
                text-decoration: underline;
            }

            p {
                text-align: center;
            }

            .line {
                position: absolute;
                top: 0px;
                left: 0px;
                height: 4px;
                width: 100%;
                background-color: #e99124;
            }
        }
    }

    @media(max-width: 700px){
        flex-direction: column;

        .box-item {
            width: 100%;
            margin-bottom: 50px;
        }
    }
`;

const ExpandingImageComponent = ({info}) => {
    return (
        <GridContainer>
            {info.map((item, i)=> {
                return <div key={`${item.key}-${i}`} className="box-item">
                    <img src={item.image.fluid.src}/>
                    <div className="box-text-wrapper">
                        <div className="line"></div>
                        <a href={item.url}>{item.title}</a>
                        <p>{item.description.description}</p>
                    </div>
                </div>
            })}
        </GridContainer>
    );
}

export default ExpandingImageComponent;